import { HTMLProps, useEffect, useState } from 'react';

import { Listbox } from '@headlessui/react';
import { Icon } from '@iwoca/orion';

export function MultiSelect<T extends { value: string; label: string }>({
  value,
  options = [],
  defaultValue,
  onChange,
  ...props
}: {
  value: T[];
  options: T[];
  defaultValue?: T[];
  onChange?: (value: T[]) => void;
  id?: string;
  name?: string;
  'data-testid'?: string;
}) {
  const [selected, setSelected] = useState<T[]>(defaultValue || []);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  return (
    <Listbox
      as="div"
      id={props.id}
      name={props.name}
      className="relative w-full"
      value={value}
      onChange={(val) => {
        const filteredValues = val.reduce<{ [key: string]: T }>((acc, curr) => {
          if (acc[curr.value]) {
            delete acc[curr.value];
          } else {
            acc[curr.value] = { ...curr };
          }

          return acc;
        }, {});
        const newValues = Object.entries(filteredValues).map(
          ([_id, option]) => option,
        );

        setSelected(newValues);
        onChange?.(newValues);
      }}
      multiple
    >
      <Listbox.Button
        as="div"
        className="relative flex min-h-[43px] w-full flex-wrap gap-s rounded-s border border-structure-80 bg-white px-m py-s font-med text-primary-10"
        data-testid={props['data-testid']}
      >
        {selected.map((option) => (
          <span
            key={option.value}
            className="rounded-s bg-secondary-80 px-s text-m"
          >
            {option.label}
          </span>
        ))}
        <Icon
          icon="angleDown"
          className="absolute right-0 top-[50%] translate-y-[-50%]"
        />
      </Listbox.Button>
      <Listbox.Options className="absolute top-[100%] z-50 mt-xs flex w-full list-none flex-col overflow-auto rounded-s border border-structure-80 bg-white p-0">
        {options.map((option) => (
          <Listbox.Option
            key={option.value}
            value={option}
            className="cursor-pointer px-m py-s text-left hover:bg-structure-95"
          >
            {option.label}
          </Listbox.Option>
        ))}
      </Listbox.Options>
    </Listbox>
  );
}
