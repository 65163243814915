import { ButtonHTMLAttributes } from 'react';

import classnames from 'classnames';

import styles from './Button.module.css';

type ButtonProps = {
  colour?: 'primary' | 'secondary';
} & ButtonHTMLAttributes<HTMLButtonElement>;

export function Button({ className, children, colour, ...props }: ButtonProps) {
  return (
    <button
      className={classnames(
        styles.button,
        className,
        styles[`${colour}Button`],
      )}
      {...props}
    >
      {children}
    </button>
  );
}
