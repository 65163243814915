import {
  fetchDeleteXeroConnections,
  fetchGetXeroAccounts,
  fetchGetXeroConnections,
  fetchGetXeroIwocapaySellerAccount,
  fetchGetXeroIwocapaySellerBrandingThemes,
} from '@iwoca/lapi-client/edge';
import { useQuery } from '@tanstack/react-query';

import { useStateKey } from '../../hooks/useStateKey.hook';

export const isSellerFeesEnabled = () => {
  return localStorage.getItem('sellerFeesEnabled') || false;
};

export const unlinkXero = async (stateKey: string, onUnlink: () => void) => {
  const response = await fetchDeleteXeroConnections({
    reason: 'iwocapay_seller',
    stateKey,
  });

  if (response.ok) {
    onUnlink();
  }
};

export const useGetXeroConnections = () => {
  const { stateKey } = useStateKey();

  const { data, error, isLoading } = useQuery({
    queryKey: ['fetchGetXeroConnectionsIwocapaySeller'],
    queryFn: () =>
      fetchGetXeroConnections({
        reason: 'iwocapay_seller',
        stateKey: stateKey!,
      }),
  });

  return { data, error, isLoading };
};

export const useGetXeroAccounts = (type: 'payment' | 'expense') => {
  const { stateKey } = useStateKey();

  const { data, error, isLoading } = useQuery({
    queryKey: [`fetchGetXero${type}Accounts`],
    queryFn: async () => {
      const res = await fetchGetXeroAccounts({
        reason: 'iwocapay_seller',
        stateKey: stateKey!,
        query: {
          account_type: type,
        },
      });
      const activeAccounts = res.data?.accounts?.filter(
        (account) => account.status === 'ACTIVE',
      );
      return activeAccounts;
    },
    enabled: !!stateKey,
  });

  return { data, error, isLoading };
};

export const useGetXeroThemes = () => {
  const { stateKey } = useStateKey();

  const { data, error, isLoading } = useQuery({
    queryKey: ['fetchGetXeroIwocapaySellerBrandingThemes'],
    queryFn: () =>
      fetchGetXeroIwocapaySellerBrandingThemes({ stateKey: stateKey! }),
    enabled: !!stateKey,
  });

  return { data, error, isLoading };
};

export const useIwocapaySellerXeroAccount = () => {
  const { stateKey } = useStateKey();

  const { data, error, isLoading } = useQuery({
    queryKey: ['fetchGetXeroIwocapaySellerAccount'],
    queryFn: () => fetchGetXeroIwocapaySellerAccount({ stateKey: stateKey! }),
    enabled: !!stateKey,
  });

  return { data, error, isLoading };
};
