import { Button } from '@iwoca/orion';

import { useSafeNavigate } from '../../../hooks/useSafeNavigate';

export const AuthorisationFailed = () => {
  const { navigate } = useSafeNavigate();
  return (
    <div className="mt-[50vh] -translate-y-1/2">
      <h2>We weren’t able to authorise you, please try again</h2>
      <Button onClick={() => navigate({ to: '/pay/integrations' })}>
        Try again
      </Button>
    </div>
  );
};
