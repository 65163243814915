import { Button } from '@iwoca/orion';

import { isSellerFeesEnabled } from './Xero.helpers';
import { XeroFormTitle } from './XeroFormTitle';

export const XeroFormLoading = ({
  firstTime = true,
}: {
  firstTime?: boolean;
}) => {
  return (
    <>
      <XeroFormTitle firstTime={firstTime} />
      <form
        className="mx-auto flex w-[500px] flex-col gap-m"
        onSubmit={() => {}}
      >
        <div className="flex flex-col items-start gap-xs">
          <label
            htmlFor="organisation"
            className="inline-block align-middle font-med text-primary-10"
          >
            Organisation
          </label>
          <select
            id="organisation"
            name="organisation"
            className="text-primary-10', w-full rounded-s border border-structure-80 p-m font-med disabled:bg-structure-95"
            disabled={true}
          >
            <option>Loading...</option>
          </select>
        </div>

        <div className="flex flex-col items-start gap-xs">
          <label
            htmlFor="paymentAccount"
            className="inline-block align-middle font-med text-primary-10"
          >
            Payment Account
          </label>
          <select
            id="paymentAccount"
            name="paymentAccount"
            className="text-primary-10', w-full rounded-s border border-structure-80 p-m font-med disabled:bg-structure-95"
            disabled={true}
          >
            <option>Loading...</option>
          </select>
        </div>

        {isSellerFeesEnabled() && (
          <div className="flex flex-col items-start gap-xs">
            <label
              htmlFor="feeAccount"
              className="inline-block align-middle font-med text-primary-10"
            >
              Fee Account
            </label>
            <select
              id="feeAccount"
              name="feeAccount"
              className="text-primary-10', w-full rounded-s border border-structure-80 p-m font-med disabled:bg-structure-95"
              disabled={true}
            >
              <option>Loading...</option>
            </select>
          </div>
        )}

        <div className="flex flex-col items-start gap-xs">
          <label
            htmlFor="invoiceThemes"
            className="inline-block align-middle font-med text-primary-10"
          >
            Invoice Themes
          </label>
          <select
            id="invoiceThemes"
            name="invoiceThemes"
            className="text-primary-10', w-full rounded-s border border-structure-80 p-m font-med disabled:bg-structure-95"
            disabled={true}
          >
            <option>Loading...</option>
          </select>

          <a
            className="text-s font-bold text-primary-40 no-underline"
            href="https://central.xero.com/s/article/Add-edit-or-delete-custom-invoice-quote-templates"
            target="_blank"
            rel="noopener noreferrer"
          >
            Add a new theme in Xero
          </a>
        </div>

        <Button className="mt-4xl" type="submit" disabled={true}>
          Submit
        </Button>
      </form>
    </>
  );
};
