export const XeroFormTitle = ({
  firstTime = true,
}: {
  firstTime?: boolean;
}) => {
  return firstTime ? (
    <div className="mx-auto mt-3xl flex w-[500px] flex-col gap-m">
      <h1 className="m-0 mb-xl mt-xl text-4xl">Welcome back!</h1>
      <div className="mb-xs text-l font-reg">
        Choose the Xero accounts you’d like your iwocaPay payments and fees to
        be recorded in.
      </div>
      <div className="mb-l text-l font-reg">
        Select the invoice branding theme you’d like to add a "pay with
        iwocaPay" button to.
      </div>
    </div>
  ) : (
    <div className="mx-auto mt-3xl flex w-[500px] flex-col gap-m">
      <h1 className="m-0 mb-xl mt-xl text-4xl">You're linked with Xero!</h1>
      <div className="mb-l text-l font-reg">
        You can update which invoice theme has a Pay with iwocaPay option within
        Xero or by re-linking your account.{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="font-bold text-primary-40 no-underline"
          href="https://support.iwoca.co.uk/en/articles/4670005-connecting-your-xero-account-to-iwocapay"
        >
          Find out more in our Xero guide.
        </a>
      </div>
    </div>
  );
};
